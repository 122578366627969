export type UserType = {
  avatar: string;
  avatarThumb: string;
  id: string;
  iin: string;
  lastname: string;
  name: string;
  phone: string;
  status: string;
  user_type: string;
};

export type UserSearchType = {
  ap_id: string;
  avatar: string;
  avatar_thumb: string;
  birth_date: string;
  created_at: string;
  first_name: string;
  gender: string;
  id: string;
  iin: string;
  last_name: string;
  middle_name: string;
  phone: string;
  status: string;
  updated_at: string;
  user_type: string;
};

export type BridgeMethodType = {
  description: string;
  id: number | string;
  name: string;
};

export type BridgeMethodResponseType = {
  data: BridgeMethodType[];
  page: number;
  size: number;
  sort: string;
  total: number;
};

export type MiniAppType = {
  bridge_methods: string[];
  descriptions: {
    en: string;
    kz: string;
    ru: string;
  };
  enabled: boolean;
  global: boolean;
  id: string;
  in_review_mode: "pending" | string;
  last_update: {
    comment: string;
    created_at: string;
    id: string;
    mini_app_id: string;
    reviewer_id: string;
    status: "pending" | "refused" | "";
    updated_at: string;
    updates: string;
    user_id: string;
  };
  link: string;
  logo: string;
  logo_url: string;
  mode: "development" | "production" | "";
  names: {
    en: string;
    kz: string;
    ru: string;
  };
  secret_key: string;
  user_id: string;
  username: string;
};

export const emptyApp: MiniAppType = {
  bridge_methods: [],
  descriptions: {
    en: "",
    kz: "",
    ru: "",
  },
  enabled: false,
  global: false,
  id: "",
  in_review_mode: "",
  last_update: {
    comment: "",
    created_at: "",
    id: "",
    mini_app_id: "",
    reviewer_id: "",
    status: "",
    updated_at: "",
    updates: "{}",
    user_id: "",
  },
  link: "",
  logo: "",
  logo_url: "",
  mode: "",
  names: {
    en: "",
    kz: "",
    ru: "",
  },
  secret_key: "",
  user_id: "",
  username: "",
}