import { Api } from "api";

export const getMiniApps: any = async () => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  };
  const res = await Api.get("/mini_apps/v1/cabinet", { headers });
  return res.data;
};

export const getMiniApp: any = async (appId: string) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  };
  const res = await Api.get(`/mini_apps/v1/cabinet/${appId}`, { headers });
  return res.data;
};

export const addMiniApp: any = async (payload: any) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  };
  const res = await Api.post("/mini_apps/v1", payload, { headers });
  return res.data;
};

export const editMiniApp: any = async (appId: string, payload: any) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  };
  const res = await Api.put(`/mini_apps/v1/${appId}`, payload, { headers });
  return res.data;
};

export const requestMiniApp: any = async (id: string) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  };
  const res = await Api.put(`/mini_apps/v1/auth/${id}/request`, {}, { headers });
  return res.data;
};

export const updateMiniApp: any = async (id: string, payload: any) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  };
  const res = await Api.post(`/mini_apps/v1/auth/${id}/update`, payload, { headers });
  return res.data;
};

export const getMiniAppLogo: any = async (id: string) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  };
  const res = await Api.get(`/files/v1/?id=${id}`, { headers });
  return res.data;
};

export const prepareLogoUpload: any = async (payload: any) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  };
  const res = await Api.post("/files/v1/upload", payload, { headers });
  return res.data;
};

export const uploadLogo: any = async (query = "", payload: any) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  };
  const res = await Api.put("/files/v1/upload" + query, payload, { headers });
  return res.data;
};

export const getCollaborators: any = async (appId: string) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  };
  const res = await Api.get(`/mini_apps/v1/${appId}/collaborators`, {
    headers,
  });
  return res.data;
};

export const addCollaborators: any = async (appId: string, payload: any) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  };
  const res = await Api.post(`/mini_apps/v1/${appId}/collaborators`, payload, {
    headers,
  });
  return res.data;
};

export const deleteCollaborators: any = async (appId: string, payload: any) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  };
  const res = await Api.delete(`/mini_apps/v1/${appId}/collaborators`, {
    data: payload,
    headers,
  });
  return res.data;
};

export const findUser: any = async (phone: string) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  };
  const res = await Api.get(`/users/v1?phone_number=${phone}`, { headers });
  return res.data;
};
