export type file = {
  channel_id: string;
  extension: string;
  filename: string;
  id: string;
  mime_type: string;
  post_id: string;
  public: boolean;
  real_filename: string;
  size: number;
  type: string;
  unique_file_id: string;
  url: string;
  user_id: string;
};

export const emptyFile: file = {
  channel_id: "",
  extension: "",
  filename: "",
  id: "",
  mime_type: "",
  post_id: "",
  public: false,
  real_filename: "",
  size: 0,
  type: "",
  unique_file_id: "",
  url: "",
  user_id: "",
};
