import { useState } from "react";
import { Card, Button, Alert } from "antd";
import SideInfo from "components/app_item/side_info";
import PushList from "components/push_list";
import { MiniAppType } from "types/miniApp";
import { FileImageTwoTone } from "@ant-design/icons";

export default function AppItem({ app }: { app: MiniAppType }) {
  const [sideDrawer, setSideDrawer] = useState<boolean>(false);
  const [pushList, setPushList] = useState<boolean>(false);

  const openPushList = (e: any) => {
    e.stopPropagation();
    setPushList(true);
  };

  return (
    <>
      <Card className="pointer" onClick={() => setSideDrawer(true)}>
        <div className="d-flex al-it-center" style={{ gap: 24 }}>
          <div className="d-flex al-it-center" style={{ gap: 16, flexBasis: "65%" }}>
            {app?.logo_url ? (
              <img src={app?.logo_url} alt="" height={60} width={60} />
            ) : (
              <FileImageTwoTone
                twoToneColor="#EF7F1A"
                style={{ fontSize: 40, justifyContent: "center", width: 60, height: 60 }}
              />
            )}
            <div style={{ width: "20vw" }}>
              <div>{app?.names.ru}</div>
              <div>{app?.descriptions.ru}</div>
            </div>
            <div
              style={{
                flex: "0 0 1px",
                height: 44,
                backgroundColor: "#E5E5E5",
              }}
            />
            <div>
              <div>Категория</div>
              <div>-</div>
            </div>
          </div>
          <div className="d-flex flex-column al-it-center" style={{ flexBasis: "15%" }}>
            <Button onClick={openPushList}>Уведомления</Button>
          </div>
          <div className="d-flex flex-column al-it-end" style={{ flexBasis: "20%" }}>
            <Alert
              message={app?.mode === "production" ? "В КАТАЛОГЕ" : "РЕЖИМ РАЗРАБОТКИ"}
              type={app?.mode === "production" ? "success" : "info"}
              style={{ width: "fit-content", fontSize: 12, fontWeight: 700 }}
            />
          </div>
        </div>
        {app?.in_review_mode && (
          <Alert
            message={app?.in_review_mode === "pending" ? "НА МОДЕРАЦИИ" : "ОТКЛОНЕНО"}
            type={app?.in_review_mode === "pending" ? "warning" : "error"}
            style={{ marginTop: 12, fontSize: 12, fontWeight: 700, textAlign: "center" }}
          />
        )}
      </Card>
      <SideInfo appInfo={app} sideDrawer={sideDrawer} setSideDrawer={setSideDrawer} />
      <PushList showPushList={pushList} hidePushList={() => setPushList(false)} appId={app?.id} />
    </>
  );
}
